<script setup lang="ts">
import SVG_SEARCH_RESULT_TITLE_ICON from '@/assets/svg/search-result-title-icon.svg'

const { t } = useI18n()

const title = computed(() => t('title.search') || 'Search Result | EasyFun.gg')
const description = computed(() => t('description.search') || 'Find the games and tags you want to play most at EasyFun.gg! Play for free now!')

useSeoMeta({
  title,
  ogTitle: title,
  description,
  ogDescription: description,
})

const { searchResultData } = useSearch(undefined, true)
</script>

<template>
  <div class="view-content flex flex-col pt-10 lg:pb-30 md:pb-10">
    <section class="flex gap-3 lt-md:flex-col md:items-end">
      <p class="m-0 flex items-center lh-1.25em font-[Bebas_Neue]">
        <SVG_SEARCH_RESULT_TITLE_ICON />
        <I18nT keypath="search.searchResTitle" tag="span" class="mis-2 flex items-center text-6 c-#fff c-op-90">
          <template #tagName>
            <span>"</span>
            <span class="inline-block max-w-35vw w-fit truncate c-green-5">{{ $route.query.s }}</span>
            <span>"</span>
          </template>
        </I18nT>
      </p>

      <I18nT keypath="search.searchResCount" tag="span" class="m-0 text-3.5 c-#fff c-op-50 lh-6">
        <template #count>
          <span class="c-green-5">{{ searchResultData.length || 0 }}</span>
        </template>
      </I18nT>
    </section>
    <section class="grid mt-10" gap="4 md:6" grid-cols="lt-md:4 md:6 lg:8">
      <template v-if="searchResultData.length">
        <CommonGameLite v-for="game in searchResultData" :key="game.gameId" :game="game" />
      </template>

      <template v-else>
        <div class="grid-col-span-8 flex-center flex-col gap-6 p-10 md:py-30">
          <img class="w-30 md:w-50" src="@/assets/images/none.png" alt="none">
          <span class="text-4 c-#fff c-op-50">{{ t('search.searchEmpty') }}</span>
        </div>
      </template>

      <ClientOnly>
        <!-- <div v-if="props.gameList.total > listData.length && props.gameList.total > 24" class="w-full flex-center -mt-6"> -->
        <!-- <CommonShowMore :status="isPending ? EShowMoreBtnStatus.LOADING : EShowMoreBtnStatus.MORE" @click="$emit('loadMore')" /> -->
        <!-- </div> -->
      </ClientOnly>
    </section>
  </div>
</template>
