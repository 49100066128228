/** 处理谷歌图片后缀 */
export function narrowGoogleImage(img: string) {
  return img.replace(/=\w\d{1,4}(-\w\d{1,4})?-rw$/, '')
}

/** 处理阿里云oss图片后缀 */
export function narrowOssImage(img: string) {
  return img.split('?x-oss-process=')[0]
}

/**
 * 处理谷歌和阿里云图片像素质量
 *
 * @export
 * @param {(string | null)} img 图片链接
 * @param {(number | [number, number])} [size] 图片缩放尺寸
 * @param {number} [q] 图片质量 默认100
 * @return {string}
 */
export function scaleImage(img: string | null, size?: number | [number, number], q: number = 100) {
  const LD_CDN = ['res.ldrescdn.com', 'res.ldplayer.net']

  // 谷歌图库
  if ((img || '').includes('play-lh.googleusercontent.com')) {
    let suffix = ''
    if (Number.isFinite(size)) {
      suffix = `=s${size}`
    }
    else if (Array.isArray(size)) {
      if (size[0])
        suffix += `=w${size[0]}`

      if (size[1])
        suffix += suffix ? `-h${size[1]}` : `=h${size[1]}`
    }
    return `${narrowGoogleImage(img!)}${suffix}`
  }
  // 阿里云oss图库
  else if (LD_CDN.some(url => (img || '').includes(url))) {
    /** 质量清晰度 10% */
    const quality = `quality,Q_${q}`

    /** 缩放 */
    let zoom = ''
    if (Number.isFinite(size))
      zoom = `resize,m_fill,h_${size},w_${size}`

    else if (Array.isArray(size))
      zoom = `resize,m_lfit,w_${size[0]},h_${size[1]}`

    return `${narrowOssImage(img!)}?x-oss-process=image/${zoom}/${quality}`
  }

  return img!
}
