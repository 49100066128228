import { ofetch } from 'ofetch'

export const businessFetch = ofetch.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  onRequest(context) {
    if (context.options?.body) {
      const { needRequestFullData, ...payload } = context.options.body as Record<string, any>
      if (needRequestFullData === true) {
        context.options.needRequestFullData = true
        context.options.body = payload
      }
    }

    if (process.server) {
      const headers = useRequestHeaders()
      Object.assign(context.options.headers || {}, headers)
    }
  },
  onRequestError(context) {
    process.server && console.warn(`Request error[${+new Date()}]: ${context.request.toString()} -> ${context.error.message}`)
  },
  onResponseError(context) {
    process.server && console.warn(`Response error[${+new Date()}]: ${context.request.toString()} -> ${context.response.status}`)
  },
  onResponse(context) {
    if (context.response.status === 200) {
      try {
        if (context.response?._data?.code !== 200) {
          if (process.server) {
            console.warn(`Response error[${+new Date()}]: ${context.response.url} -> ${JSON.stringify(context.response._data)}`)
          }
          else if (context.response?._data?.code === 401) {
            const { cleanUserInfo } = useUserInfo()
            cleanUserInfo()
          }
        }
      }
      catch (err) {}
      if (!context.options.needRequestFullData)
        context.response._data = context.response?._data.data
    }
  },
})

export const nodeBackendFetch = ofetch.create({
  baseURL: import.meta.env.VITE_NODE_BACKEND_ENDPOINT,
})
