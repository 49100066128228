/* eslint-disable antfu/top-level-function */
import { initLdSdk, overseaLogin } from 'ld-web-sdk'
import { googleOneTap } from 'vue3-google-login'
import type { IUserIpInfo, TCustomOverseaLoginUserInfo, TLoginSDKGoogleResult } from '@/types/api'
import { ELoginSuccessChannels, ELoginSuccessSource } from '@/types/components'

const LOCAL_USERINFO_KEY_NAME = 'EASYFUN_USERINFO'
const LOCAL_USERIP_KEY_NAME = 'EASYFUN_USERIP'
const LOCAL_USER_REMAINING_TIME_NAME = 'EASYFUN_REMAINING_TIME'
const LOCAL_UPDATE_TOKEN_RECORD = 'EASYFUN_LAST_UPDATE_TOKEN'
const LOCAL_FRIGHT_PRINT = 'EASYFUN_FINGER_PRINT'

export const usePersonalPopup = () => {
  const isShowPersonalPopup = useState<boolean>(() => false)
  const setIsShowPersonalPopup = (value: boolean) => {
    isShowPersonalPopup.value = value
  }
  return {
    isShowPersonalPopup,
    setIsShowPersonalPopup,
  }
}

export const useUserInfo = ({ needGooglePrompt } = { needGooglePrompt: false }) => {
  const waitSdkInit = useState<null | Promise<void>>('wait-sdk-init', () => null)
  const loginSource = useState<ELoginSuccessSource>(() => ELoginSuccessSource.UNKNOWN)
  const userInfo = useLocalStorage<TCustomOverseaLoginUserInfo | Record<string, never>>(LOCAL_USERINFO_KEY_NAME, {}, {
    serializer: {
      read: (v: string) => v ? JSON.parse(v) : {},
      write: JSON.stringify,
    },
  })
  const lastRenewToken = useLocalStorage<number>(LOCAL_UPDATE_TOKEN_RECORD, 0, {
    serializer: {
      read: Number,
      write: JSON.stringify,
    },
  })

  const userIp = useState<IUserIpInfo | undefined>(LOCAL_USERIP_KEY_NAME, () => undefined)
  const remainingTime = useState<number | undefined>(LOCAL_USER_REMAINING_TIME_NAME, () => undefined)

  const isLogged = computed(() => !!userInfo.value?.token)
  // 12 hours
  const isNeedUpdateToken = computed(() => +new Date() - lastRenewToken.value >= 43200000 && isLogged.value)

  const registerSid = () => {
    if (!waitSdkInit.value) {
      // @ts-expect-error eslint-disable-next-line
      waitSdkInit.value = initLdSdk({
        region: 'oversea',
        appId: import.meta.env.VITE_API_LOGIN_APPID,
        extAppId: import.meta.env.VITE_API_LOGIN_EXT_APPID,
        languageCode: 'en',
        develop: !Number(import.meta.env.VITE_IS_PROD_ENV),
        code: '',
        robotCheck: true,
      })
    }
  }

  async function googleLoginPrompt() {
    const response = await googleOneTap()
    await waitSdkInit.value
    const { google: loginByGoogleApi } = overseaLogin.createLogin()
    const data = await loginByGoogleApi({ auth: response.credential }) as TLoginSDKGoogleResult
    userInfo.value = Object.assign(data, { email: data.thirdUserInfos[0].thirdEmail })
    try {
      const { uid, email, nickname } = userInfo.value
      window?.dataLayer?.push({ event: 'login-success', uid, email, nickname, type: ELoginSuccessChannels.GOOGLE_ONE_TAP, source: ELoginSuccessSource.GOOGLE_ONE_TAP })
    }
    catch (e) {}
  }

  const cleanUserInfo = () => {
    userIp.value = undefined
    userInfo.value = null
  }

  const isTokenUpdating = useState<boolean>('TOKEN_UPDATE', () => false)
  const handleUpdateToken = async () => {
    if (isTokenUpdating.value)
      return

    isTokenUpdating.value = true
    try {
      const { auto } = overseaLogin.createLogin()
      lastRenewToken.value = +new Date()
      const response = await auto()
      userInfo.value = Object.assign(userInfo.value, { token: response.token, shortToken: response.shortToken })
    }
    finally {
      isTokenUpdating.value = false
    }
  }

  onBeforeMount(() => {
    registerSid()
  })

  onMounted(() => {
    (needGooglePrompt && !isLogged.value) && googleLoginPrompt()
    isNeedUpdateToken.value && handleUpdateToken()
  })

  watchEffect(() => {
    if (isLogged.value) {
      try {
        if (!userInfo.value.email && !userInfo.value.headPortraitUrl) {
          // fix bug dirty data
          cleanUserInfo()
        }

        window.__bl.setConfig({
          uid: userInfo.value.uid,
          setUsername: () => userInfo.value?.nickname,
        })
      }
      catch (err) {}
    }
  })

  return {
    isLogged,
    userInfo,
    waitSdkInit,
    loginSource,
    cleanUserInfo,

    userIp,
    remainingTime,
  }
}

export const useVisitor = () => {
  const sdk = useState<Promise<unknown>>()
  const fingerprint = useLocalStorage<string>(LOCAL_FRIGHT_PRINT, '')

  async function getFingerprint() {
    if (!fingerprint.value)
      await generateFingerprint()
    return fingerprint.value
  }

  async function generateFingerprint() {
    if (!sdk.value)
      sdk.value = import('@fingerprintjs/fingerprintjs')
    const { load } = await sdk.value as { load: Function }
    const fp = await load()
    const result = await fp.get()
    fingerprint.value = result.visitorId
  }

  onMounted(() => {
    const timer = setTimeout(() => {
      clearTimeout(timer)
      !fingerprint.value && generateFingerprint()
    }, 2000)
  })

  return {
    getFingerprint,
  }
}
