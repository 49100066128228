import { businessFetch, nodeBackendFetch } from '~/api/fetch'
import type * as API_TYPE from '@/types/api'
import type { ICommonGameBlockProps, IHistoryGameProps, ITagBlockProps } from '@/types/components'

/**
 * @see {@link https://console-docs.apipost.cn/preview/202e7f9661a510b6/82abe1a1798a977d?target_id=b3f1f960-92c8-4b34-b781-b7b35670d31c|API Doc}
 */
export const API_INDEX_DATA = (limit: number) => businessFetch<API_TYPE.IIndexData>(`/ows/en/easyfun/index`, { query: { limit } })

/**
 * @see {@link https://console-docs.apipost.cn/preview/202e7f9661a510b6/82abe1a1798a977d?target_id=edc82d9e-3ec0-4574-a0c0-4ec6c5420d4c|API Doc}
 */
export const API_GAME_DATA = (name: string) => businessFetch<API_TYPE.IGameData>(`/ows/en/easyfun/game/${name}`)

/**
 * @see {@link https://console-docs.apipost.cn/preview/202e7f9661a510b6/82abe1a1798a977d?target_id=cdbe35fa-c812-443e-bbc9-13ddd3c12d50|API Doc}
 */
export const API_VISITOR_AUTH = (identify: string) => businessFetch<{ token: string, uid: string }>(`/tourists/${identify}`, { method: 'POST' })

/**
 * @see {@link https://console-docs.apipost.cn/preview/202e7f9661a510b6/82abe1a1798a977d?target_id=81c94d1e-4b4f-4732-b1d3-092b68aa4822|API Doc}
 */
export const API_RECORD_ERROR = (msg: string) => businessFetch<{ token: string, uid: string }>(`/auth/raiseAlert`, { method: 'POST', params: { msg } })

/**
 * @see {@link https://console-docs.apipost.cn/preview/202e7f9661a510b6/82abe1a1798a977d?target_id=7eb75ba9-ecdf-406e-add3-118ae57cff2e|API Doc}
 */
export const API_SEARCH_GAMES = (query: API_TYPE.IGetSearchResultQuery) => businessFetch<Array<ICommonGameBlockProps>>(`/ows/en/easyfun/search`, { query })

/**
 * @see {@link https://console-docs.apipost.cn/preview/202e7f9661a510b6/82abe1a1798a977d?target_id=396d89b4-31f3-4604-a27b-4262b3c9404d|API Doc}
 */
export const API_GET_TAG_GAMES = (name: string) => businessFetch<API_TYPE.ITagsGameResponseData>(`/ows/en/easyfun/category/game`, { query: { category: name } })

/**
 * @see {@link https://console-docs.apipost.cn/preview/202e7f9661a510b6/82abe1a1798a977d?target_id=027ecc2f-7872-4ecd-b4af-1eef197704b8|API Doc}
 */
export const API_GET_ALL_TAGS = () => businessFetch<Array<ITagBlockProps>>(`/ows/en/easyfun/category/all`)

/**
 * @see {@link https://console-docs.apipost.cn/preview/202e7f9661a510b6/82abe1a1798a977d?target_id=41c9f729-db6f-4862-8dc3-78f18dc6e240|API Doc}
 */
export const API_GET_RECOMMENDED_TAGS = () => businessFetch<Array<ITagBlockProps>>(`/ows/en/easyfun/category/recommend`)

/**
 * @see {@link https://doc.apipost.net/docs/detail/2bd814cd7c64000?target_id=a818e773028|API Doc}
 */
export const API_GET_WS_LINK = (payload: Record<string, string>) => businessFetch<{ code: number, data: string | null }>(`/auth/socket`, { method: 'POST', body: { ...payload, needRequestFullData: true } })

/**
 * @see {@link https://doc.apipost.net/docs/detail/2bd814cd7c64000?target_id=19d259c1b9a003|API Doc}
 */
export const API_SAVE_GAME_KEY = (payload: Record<string, string | number>) => businessFetch<string>(`/auth/button`, { method: 'POST', body: payload })

/**
 * @see {@link https://doc.apipost.net/docs/detail/2bd814cd7c64000?target_id=4ef6571|API Doc}
 */
export const API_USER_IP = () => businessFetch<API_TYPE.IUserIpInfo>(`/auth/ip`)

/**
 * @see {@link https://doc.apipost.net/docs/detail/2bd814cd7c64000?target_id=9a3ec773003|API Doc}
 */
export const API_USER_HISTORY_GAMES = (params: Record<'uid' | 'token', string>) => businessFetch<IHistoryGameProps[]>(`/auth/history`, { params })

/**
 * @see {@link https://doc.apipost.net/docs/detail/2bd814cd7c64000?target_id=34ea1c3d3bb000|API Doc}
 */
export const API_USER_REMAINING_TIME = (params: Record<'uid' | 'token', string>) => businessFetch<number>(`/auth/getRemainingTime`, { params })

/**
 * clear game detail page cache
 */
export function API_CLEAR_GAME_DETAIL_CACHE(payload: Array<string>) {
  return nodeBackendFetch(`/__nuxt_multi_cache/handle-cache/purge/tags`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'x-nuxt-multi-cache-token': 'Rayen_9up',
    },
  })
}

/**
 * @see {@link https://doc.apipost.net/docs/detail/2bd814cd7c64000?target_id=173c0a470000f|API Doc}
 */
export const API_SAVE_CUSTOM_BTN_CONFIG = (payload: API_TYPE.IUserSaveButtonPayload) => businessFetch(`/auth/binding-user-button`, { method: 'POST', body: payload })

export const API_GET_USER_BTN_CONFIG = ({ uid, gameId, token }: { uid: string, token: string, gameId: number }) => businessFetch<{ buttonName: string, configData: '', buttonId: string }[]>(`/auth/user-game-button?uid=${uid}&gameId=${gameId}&token=${token}`)
