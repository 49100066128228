import type { IDeviceInfo } from './websocket'

export enum EGameBlockSize { MD = 'md', SM = 'sm', XS = 'xs', XXS = 'xxs', LINE = 'line' };
export enum ETagBlockStatus { ACTIVE = 'active', INACTIVE = 'inactive', DEFAULT = 'default' };
export enum ELoginDialogType { LOGIN, SIGNUP, RESET };
export enum EGameType { CLOUD = 1, WEBSITE = 2 }
export enum EShowMoreBtnColor { GREEN = 1, BLACK = 2 }
export enum EShowMoreBtnStatus { LOADING = 0, MORE = 1, LESS = 2 }
export enum EToastType { SUCCESS = 'success', ERROR = 'error', INFO = 'info' }
export enum EGamePlayGroundAlertEvent { CLOSE = 'close', CONFIRM = 'confirm', SIGNUP = 'signup' }
export enum EVisitorGamePlayBtnChannels { TRIAL = 1, LOGIN = 2 }
export enum ELoginSuccessChannels { REGISTER = 1, EMAIL = 2, GOOGLE = 3, GOOGLE_ONE_TAP = 4 }
export enum ELoginSuccessSource { UNKNOWN = 0, GAME_DETAIL_VISITOR_PLAY_BTN = 1, GOOGLE_ONE_TAP = 2, NAV_ICON = 3, GAME_PANEL_TIPS_BAR_BTN = 4, QUEUE_POPUP_BTN = 5, TIME_EXPIRED_POPUP = 6 }
export enum EGameOrientation {
  /** 竖屏 */
  PORTRAIT = 0,
  /** 横屏 */
  LANDSCAPE = 1,
}

export interface ICommonGameBlockProps {
  gameId: number
  gameName: string
  icon: string
  seo: string
  status: boolean
  banner: string
  gameType: EGameType
}

export interface IGamePlayGroundProps extends ICommonGameBlockProps {
  content: string
  playLink: string
  deviceType: null | string
  packageName: string
  description: string | null
  photos: string | null
  button: string | null
  screenOrientation: EGameOrientation
}

export interface IHomeGameListProps {
  data: Array<ICommonGameBlockProps>
  total: number
  size: number
  pages: number
  current: number
}
export interface TGameTimeRangeProps extends Pick<IDeviceInfo, 'startTime' | 'endTime' | 'lastDisconnectTime'> {
  /** 返回设备信息时候的time */
  pongTime: number
  /** ws 返回的time */
  currentTime: number
}

interface IHomeBannerProps extends ICommonGameBlockProps {
  // images: string
  // wash-data 前端转换之后
  images: Record<'roleImage' | 'bgImage', string>
  link: string
}

export type THomeRecommendedGameListProps = Array<ICommonGameBlockProps>

export type THomeBannerListProps = Array<IHomeBannerProps>

export enum EGamePlayGroundAlertType {
  CLOSE_ALERT = 0,
  SERVER_LOCATION_TIPS = 1,
  LONG_TIME_NO_ACTIVE = 2,
  DUPLICATE_CONNECTION = 3,
  QUEUE_INFO = 4,
  FREE_GAME_TIME_EXPIRED = 5,
  TOTAL_GAME_TIME_EXPIRED = 6,
  REMINDER_CHANGE_BROWSER = 7,
  SERVER_IN_DOWNTIME = 8,
  CHECK_EXIT = 9,
}

export interface ITagBlockProps {
  id: number
  icon: string
  categoryName: string
  parentId: number
  recommend: number
  sort: number
  labelKey: string | null
  titleKey: string | null
  descriptionKey: string | null
  directions: string | null
}

export enum EControlBtnUseType {
  EDIT,
  ADD,
}

export enum ECloudGameEditBarBtnType {
  PUBLISH = 0,
  QUIT = 1,
  RESET = 2,
  SAVE = 3,
}

export interface IHistoryGameProps extends ICommonGameBlockProps {
  uid: string
  /** 最晚开始时间 */
  latestStartTime: string
  /** 总分钟数 */
  totalMinutes: number
}
