import { API_SEARCH_GAMES } from '@/api'

import type { IGetSearchResultQuery } from '@/types/api'
import type { ICommonGameBlockProps } from '@/types/components'

export function useSearch(limit?: number, isQuerySearch?: boolean) {
  const route = useRoute()
  const keyword = ref('')
  const searchResultData = ref<Array<ICommonGameBlockProps>>([])

  const handleSearchGame = async (s?: string) => {
    const payload: IGetSearchResultQuery = { keyWords: s || keyword.value }
    if (limit)
      payload.limit = limit

    const res = await API_SEARCH_GAMES(payload)
    searchResultData.value = res
  }

  watch(keyword, useThrottleFn(() => {
    handleSearchGame()
  }, 50))

  watchEffect(() => {
    if (!isQuerySearch)
      return
    const s = route.query.s as string
    s && handleSearchGame(s)
  })
  return {
    keyword,
    searchResultData,
  }
}
